import React, { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router";
import AuthContext from "store/auth-context";
import AxiosContext from "store/axios-context";
import Details from "./Details";

const quoteFields = {
  cust_id: "",
  from_id: "",
  to_id: "",
  pieces: "",
  act_wt: "",
  charge_wt: "",
  volume: "",
  shipment_id: "",
  currency: "",
  description: "",
  hide_total: false,
  items: [],
};
export default function Manage() {
  const history = useHistory();
  const axiosCtx = useContext(AxiosContext);
  const authCtx = useContext(AuthContext);
  const params = useParams();
  const [data, setData] = useState(quoteFields);
  const [customers, setCustomers] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [shipmentTerms, setShipmentTerms] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    if (authCtx.isLoggedIn === false) {
      history.replace("/auth/Login");
    }
    setIfHaveEditingData();
    prepareCustomers();
  }, []);

  const setIfHaveEditingData = () => {
    if (params.id) {
      axiosCtx.instance
        .get(`show-quotation?id=${params.id}`)
        .then((response) => {
          setData(response.data.data);
        })
        .catch((error) => {
          alert("Something went wrong!");
        });
    }
  };

  const prepareCustomers = () => {
    axiosCtx.instance
      .get("party")
      .then((response) => {
        setCustomers(response.data.data);
      })
      .catch((error) => {})
      .then(() => prepareCurrencies());
  };
  const prepareCurrencies = () => {
    axiosCtx.instance
      .get("currency")
      .then((response) => {
        setCurrencies(response.data.data);
      })
      .catch((error) => {})
      .then(() => prepareDestinations());
  };

  const prepareDestinations = () => {
    axiosCtx.instance
      .get("port")
      .then((response) => {
        setDestinations(response.data.data);
      })
      .catch((error) => {})
      .then(() => prepareShipmentTerms());
  };

  const prepareShipmentTerms = () => {
    axiosCtx.instance
      .get("get-shipement-term")
      .then((response) => {
        setShipmentTerms(response.data.data);
      })
      .catch((error) => {});
  };

  return (
    <Fragment>
      <Details
        data={data}
        customers={customers}
        destinations={destinations}
        shipmentTerms={shipmentTerms}
        currencies={currencies}
      />
    </Fragment>
  );
}
