// import * as React from 'react';
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";

import { Button, Card, Col, Modal, Row } from "react-bootstrap";
// reactstrap components
import { useHistory } from "react-router";
import FadeLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";
import AxiosContext from "store/axios-context";
import AuthContext from "store/auth-context";
import InputField from "./Fields/InputField";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          className="font-weight-bold"
          key="title"
          sortDirection={orderBy === "title" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "title"}
            direction={orderBy === "title" ? order : "asc"}
            onClick={createSortHandler("title")}
          >
            Title
            {orderBy === "title" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
        <TableCell style={{ width: "200px" }}></TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return <span></span>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ShippingTerm() {
  const history = useHistory();
  const axiosCtx = useContext(AxiosContext);
  const authCtx = useContext(AuthContext);
  const [shippingTerms, setShippingTerms] = useState([]);
  const [edit, setEdit] = useState({});
  const [formError, setFormError] = useState(null);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const prepareShippingTerms = () => {
    setLoading(true);
    axiosCtx.instance
      .get(`get-shipement-term`)
      .then((response) => {
        setLoading(false);
        setShippingTerms(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (authCtx.isLoggedIn == false) {
      history.replace("/auth/Login");
    }
    prepareShippingTerms();
  }, []);

  const [showDelete, setShowDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleCloseDelete = () => {
    setShowDelete(false);
    setDeleteMessage("");
    setDeleteId("");
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = shippingTerms.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - shippingTerms.length) : 0;

  const saveTitle = () => {
    let url = "store-shipement-term";
    let data = { title: title };
    if (edit.id) {
      url = `update-shipement-term`;
      data.id = edit.id;
    }
    axiosCtx.instance
      .post(url, data)
      .then((response) => {
        setEdit({});
        prepareShippingTerms();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          setFormError(error.response.data);
        }
      });
  };

  function editShipmentTerm(data) {
    setTitle(data.title);
    setEdit(data);
  }

  const onShipmentDeleteHandler = (item) => {
    setDeleteId(item);
    setShowDelete(true);
  };
  const deleteSubmitHandler = () => {
    axiosCtx.instance
      .get(`delete-shipement-term?id=${deleteId}`)
      .then((response) => {
        prepareShippingTerms();
        handleCloseDelete();
      })
      .catch((error) => {
        alert("Something went wrong !");
      });
  };

  return (
    <div className="content">
      {loading && loading == true ? (
        <div className="custom-loader">
          <FadeLoader
            color="#2f67c9"
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : (
        ""
      )}
      <Row>
        <Col md="5">
          <Card className="p-3">
            <h4 className="font-weight-bold">Create Shipping Term</h4>
            <div>
              <InputField
                id="title"
                placeholder="Title"
                label="Title"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                formError={
                  formError && formError.errors.title
                    ? formError.errors.title
                    : ""
                }
              />
              <div className="text-right border-top mt-4">
                <button onClick={saveTitle} className="btn btn-primary mt-4">
                  Save
                </button>
              </div>
            </div>
          </Card>
        </Col>
        <Col md="7">
          <Card className="p-3">
            <div className="d-flex justify-content-between align-items-center border-bottom mb-4">
              <h4 className="font-weight-bold m-0">Shipping Terms</h4>
            </div>
            <Box sx={{ width: "100%" }}>
              <Paper sx={{ width: "100%", mb: 2 }}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                  <Table
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={shippingTerms.length}
                    />
                    <TableBody>
                      {stableSort(shippingTerms, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              <TableCell>{row.title}</TableCell>
                              <TableCell className="text-center">
                                <button
                                  className="btn btn-primary btn-sm font-weight-bold my-2 mx-1"
                                  onClick={() => editShipmentTerm(row)}
                                >
                                  Edit
                                </button>
                                <button
                                  onClick={() =>
                                    onShipmentDeleteHandler(row.id)
                                  }
                                  className="btn btn-danger btn-sm font-weight-bold my-2 mx-1"
                                >
                                  Delete
                                </button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: (dense ? 33 : 53) * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={shippingTerms.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          </Card>
        </Col>
      </Row>
      <Modal show={showDelete} onHide={handleCloseDelete} size="lg">
        <Modal.Header className="p-4">
          <Modal.Title className="m-0">Delete Job </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <Modal.Title className="m-0">Are you sure to do this ? </Modal.Title>
          <p>This will remove all data related to this shipment term</p>
          <p>{deleteMessage}</p>
        </Modal.Body>
        <Modal.Footer className="p-4">
          <Button
            className="mr-3"
            variant="secondary"
            onClick={handleCloseDelete}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={deleteSubmitHandler}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
