import InputField from "components/Fields/InputField";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Item({
  index,
  data,
  setValues,
  removeItem,
  formErrors,
}) {
  const getInputValue = (e) => {
    if (e.target.value) {
      return e.target.value;
    }
    return 0;
  };
  const getIntVal = (number) => {
    return Number(parseFloat(number).toFixed(4));
  };
  const onChangeTitle = (e) => {
    data.name = getInputValue(e);
    setValues(index, data);
  };
  const onChangeCost = (e) => {
    const value = getInputValue(e);
    data.profit = getIntVal((getIntVal(data.margin) * getIntVal(value)) / 100);
    data.total = getIntVal(data.profit) + getIntVal(value);
    data.cost = value;
    setValues(index, data);
  };
  const onChangeMargin = (e) => {
    const value = getInputValue(e);
    data.profit = getIntVal((getIntVal(value) * getIntVal(data.cost)) / 100);
    data.total = getIntVal(data.profit) + getIntVal(data.cost);
    data.margin = value;
    setValues(index, data);
  };
  const onChangeTotal = (e) => {
    const value = getInputValue(e);
    data.cost = getIntVal(value) - getIntVal(data.profit);
    data.margin = getIntVal(
      (getIntVal(data.profit) / getIntVal(data.cost)) * 100
    );
    data.total = value;
    setValues(index, data);
  };
  const onChangeProfit = (e) => {
    const value = getInputValue(e);
    data.profit = value;
    data.cost = getIntVal(data.total) - getIntVal(value);
    data.margin = getIntVal(
      (getIntVal(data.profit) / getIntVal(data.cost)) * 100
    );
    setValues(index, data);
  };
  const onChangeComment = (e) => {
    data.comment = e.target.value;
    setValues(index, data);
  };

  const getErrorMessage = (field) => {
    if (data.errors && data.errors[field]) {
      return data.errors[field];
    }
    const key = `items.${index}.${field}`;
    if (formErrors && formErrors.errors && formErrors.errors[key])
      return formErrors.errors[key].map((error) => error.replace(key, field));
  };
  return (
    <tr className="p-3 mt-4">
      <td style={{ minWidth: "250px" }}>
        <InputField
          id="title"
          placeholder="Item Name"
          label=""
          value={data.name}
          onChange={onChangeTitle}
          formError={getErrorMessage("name")}
        />
      </td>
      <td>
        <InputField
          id="cost"
          placeholder="Cost"
          label=""
          value={data.cost}
          onChange={onChangeCost}
          formError={getErrorMessage("cost")}
        />
      </td>
      <td>
        <InputField
          id="margin"
          placeholder="Margin(%)"
          label=""
          value={data.margin}
          onChange={onChangeMargin}
          formError={getErrorMessage("margin")}
        />
      </td>
      <td>
        <InputField
          id="total"
          placeholder="Total"
          label=""
          value={data.total}
          onChange={onChangeTotal}
          formError={getErrorMessage("total")}
        />
      </td>
      <td>
        <InputField
          id="profit"
          placeholder="Profit"
          label=""
          value={data.profit}
          onChange={onChangeProfit}
          formError={getErrorMessage("profit")}
        />
      </td>
      <td style={{ minWidth: "250px" }}>
        <InputField
          id="comment"
          placeholder="Comment"
          label=""
          value={data.comment}
          onChange={onChangeComment}
          formError={getErrorMessage("comment")}
        />
      </td>
      <td>
        <DeleteIcon
          onClick={() => removeItem(index)}
          className="text-danger cursor-p"
        />
      </td>
    </tr>
  );
}
