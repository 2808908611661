import React, { useContext, useEffect, useState } from "react";
import { Accordion, Button } from 'react-bootstrap'
import { useParams } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
// reactstrap components
import {
    Card,
    Table,
    Row,
    Col,
} from "reactstrap";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import AxiosContext from '../store/axios-context';
import AuthContext from '../store/auth-context';
import { useHistory } from 'react-router';
import FadeLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function WarehouseEdit(props) {

    const history = useHistory();
    const [formError, setFormError] = useState(null);
    const [commonError, setCommonError] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const [airwaybillsLocalorders, setAirwaybillsLocalorders] = useState(null);
    const [selectedAirwaybillLocalOrder, setSelectedAirwaybillLocalOrder] = useState(null);
    const [locs, setLocs] = useState(null);
    const [loc, setLoc] = useState(null);
    const [rack, setRack] = useState(null);
    const [racks, setRacks] = useState(null);
    const [totalPackages, setTotalPackages] = useState('');
    const [packagesInDate, setPackagesInDate] = useState('');
    const [numberOfPackagesOut, setNumberOfPackagesOut] = useState('');
    const [packagesInWarehouse, setPackagesInWarehouse] = useState('');
    const [warehouseId, setWarehouseId] = useState('');

    const axiosCtx = useContext(AxiosContext);
    const authCtx = useContext(AuthContext);

    const getWarehouses = () => {
        axiosCtx.instance.get(`warehouse`)
            .then((response) => {
                setWarehouses(response.data.data);
            })
            .catch((error) => {
                // setFormError(error.response.data);
                // setLoading(false);
            });
    };

    const getAirwaybillsLocalorders = () => {
        axiosCtx.instance.get(`airwaybills-localorders`)
            .then((response) => {
                setAirwaybillsLocalorders(response.data.data);
            })
            .catch((error) => {
                // setFormError(error.response.data);
                // setLoading(false);
            });
    };

    const getLocations = () => {
        axiosCtx.instance.get(`location`)
            .then((response) => {
                setLocs(response.data.data);
            })
            .catch((error) => {
                // setFormError(error.response.data);
                // setLoading(false);
            });
    };

    const getRacks = () => {
        axiosCtx.instance.get(`rack`)
            .then((response) => {
                setRacks(response.data.data);
            })
            .catch((error) => {
                // setFormError(error.response.data);
                // setLoading(false);
            });
    };

    const { id } = useParams();

    useEffect(() => {
        if (authCtx.isLoggedIn == false) {
            history.replace('/auth/Login');
        }
        getWarehouses();
        getRacks();
        getLocations();
        getAirwaybillsLocalorders();
        getWarehouseId(id);
    }, []);


    const getWarehouseId = (id) => {
        setLoading(true);

        const warehouseRequest = axiosCtx.instance.get(`warehouse/${id}`);

        Promise.all([warehouseRequest]).then(([warehouseResponse]) => {

            if (warehouseResponse.status === 200) {
                let response = warehouseResponse.data.data;

                setWarehouseId((response.id) ? response.id : '');
                setSelectedAirwaybillLocalOrder((response.airwaybill_localorder) ? response.airwaybill_localorder : null);
                setLoc((response.location) ? response.location : null);
                // setRack((response.rack) ? response.rack : null);
                setTotalPackages((response.total_packages) ? response.total_packages : '');
                setNumberOfPackagesOut((response.num_packages_out) ? response.num_packages_out : '');
                // setPackagesInWarehouse((response.packages_in_warehouse) ? response.packages_in_warehouse : '');
                // setPackagesInDate((response.packages_in_date) ? response.packages_in_date : '');
                // setPackagesOutValues((response.packages_out !== null) ? JSON.parse(response.packages_out) : "{ date: '', no_of_packages_out: 0 }");
                setLoading(false);
            }

        }).catch((error) => {
            // TODO: Handle Error
            console.log(error)
            setLoading(false);
            alert('Sorry, something went wrong!');
        });

    }

    const submitHandler = (event) => {
        event.preventDefault();
        setFormError(null);
        setLoading(true);

        // Call login API
        const params = {
            airway_bill_localorder: (selectedAirwaybillLocalOrder) ? selectedAirwaybillLocalOrder.order_number : '',
            location: (loc) ? loc.id : '',
            rack: (rack) ? rack.id : '',
            total_packages: totalPackages,
            num_packages_out: numberOfPackagesOut,
            packages_in_date: packagesInDate,
            packages_in_warehouse: packagesInWarehouse,
            packages_out: JSON.stringify(packagesOutValues),
        };

        if (numberOfPackagesOut > totalPackages) {
            var err = "Number of packages must be less than total packages";
            setCommonError(err);
        }
        else {
            axiosCtx.instance.put(`warehouse/` + warehouseId, params)
                .then((response) => {
                    setLoading(false);
                    history.push({
                        pathname: `/warehouse`,
                        state: {}
                    });
                })
                .catch((error) => {
                    setFormError(error.response.data);
                    setLoading(false);
                });
        }

    };

    const [packagesOutValues, setPackagesOutValues] = useState([{ date: "", no_of_packages_out: 0 }])

    const addPackagesOutField = () => {
        setPackagesOutValues([...packagesOutValues, { date: "", no_of_packages_out: 0 }])
    };

    const onBlurNoOfPackages = (i, e) => {
        const totalOut = packagesOutValues.reduce((total, currentValue) => (!isNaN(parseInt(currentValue.no_of_packages_out) + parseInt(total))) ? total = parseInt(total) + parseInt(currentValue.no_of_packages_out) : total = total, 0);
        if (totalOut > totalPackages) {
            alert("No of packages out should be less than total packages");
            let formValues = [...packagesOutValues];
            formValues[i][e.target.name] = 0;
            setPackagesOutValues(formValues);
        }
        else {
            setNumberOfPackagesOut(totalOut);
            setPackagesInWarehouse(totalPackages - totalOut);
        }

    }

    let removePackagesField = (index) => {
        let newFormValues = [...packagesOutValues];
        newFormValues.splice(index, 1);
        setPackagesOutValues(newFormValues);
        const totalOut = packagesOutValues.reduce((total, currentValue) => (!isNaN(parseInt(currentValue.no_of_packages_out) + parseInt(total))) ? total = parseInt(total) + parseInt(currentValue.no_of_packages_out) : total = total, 0);
        setNumberOfPackagesOut(totalOut);
        setPackagesInWarehouse(totalPackages - totalOut);
    }

    const handlePackagesFieldChange = (i, e) => {
        let formValues = [...packagesOutValues];
        formValues[i][e.target.name] = e.target.value;
        setPackagesOutValues(formValues);
    }

    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#2f67c9");

    return (
        <>
            <div className="content">
                {loading && loading == true ?
                    <div className="custom-loader">
                        <FadeLoader color={color} loading={loading} css={override} size={150} />
                    </div>
                    : ''
                }
                <Card >
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Edit Warehouse</Accordion.Header>
                            <Accordion.Body>
                                <Row>

                                    <Col md="6" className="my-4">
                                        <FormControl variant="outlined" className="w-100">
                                            <Autocomplete
                                                defaultValue={selectedAirwaybillLocalOrder || null}
                                                size="small"
                                                disabled={(warehouseId) ? true : false}
                                                onChange={(event, newValue) => {
                                                    setSelectedAirwaybillLocalOrder(newValue);
                                                    setTotalPackages(newValue.pieces);
                                                }}
                                                disablePortal
                                                id="party-box-demo"
                                                options={airwaybillsLocalorders}
                                                getOptionLabel={(option) => option.order_number}
                                                renderInput={(params) => <TextField {...params} label="Airwaybill/Localorder Number" />}
                                            />
                                            {formError &&
                                                formError.errors.have_warehouse && (
                                                    <p className="text-danger small pt-2">
                                                        {formError.errors.have_warehouse}
                                                    </p>
                                                )}

                                        </FormControl>

                                    </Col>
                                    <Col md="6" className="my-4">
                                        <TextField disabled={true} size="small" className="w-100" onChange={(e) => setTotalPackages(e.target.value)} value={totalPackages} label="Total Packages" variant="outlined" />
                                    </Col>
                                    <Col md="6" className="my-4">
                                        <FormControl variant="outlined" className="w-100">
                                            <Autocomplete
                                                value={loc || null}
                                                size="small"
                                                onChange={(event, newValue) => {
                                                    setLoc(newValue);
                                                }}
                                                disablePortal
                                                id="party-box-demo"
                                                options={locs}
                                                getOptionLabel={(option) => option.name}
                                                renderInput={(params) => <TextField {...params} label="Location" />}
                                            />


                                        </FormControl>
                                    </Col>
                                    <Col md="6" className="my-4">
                                        <FormControl variant="outlined" className="w-100">
                                            {/* <InputLabel id="select1">Sub Type</InputLabel> */}
                                            <Autocomplete
                                                value={rack || null}
                                                size="small"
                                                onChange={(event, newValue) => {
                                                    setRack(newValue);
                                                }}
                                                disablePortal
                                                id="party-box-demo"
                                                options={racks}
                                                getOptionLabel={(option) => option.name}
                                                renderInput={(params) => <TextField {...params} label="Rack" />}
                                            />


                                        </FormControl>
                                    </Col>

                                    <Col md="6" className="my-4">
                                        <TextField type="date" size="small" className="w-100" onChange={(e) => setPackagesInDate(e.target.value)} value={packagesInDate} label="Packages In Date" variant="outlined" InputLabelProps={{
                                            shrink: true,
                                        }} />
                                    </Col>
                                    <>
                                        <Col md="6" className="my-4 form-group d-flex">
                                            <TextField disabled={true} size="small" className="w-100" onChange={(e) => setPackagesInWarehouse(e.target.value)} value={packagesInWarehouse} label="Packages In Warehouse" variant="outlined" InputLabelProps={{
                                                shrink: true,
                                            }} />
                                            {formError &&
                                                formError.errors.packages_in_warehouse && (
                                                    <p className="text-danger small pt-2">
                                                        {formError.errors.packages_in_warehouse}
                                                    </p>
                                                )}
                                            <TextField disabled={true} size="small" className="w-100" onChange={(e) => setNumberOfPackagesOut(e.target.value)} value={numberOfPackagesOut} label="Number Of Packages out" variant="outlined" InputLabelProps={{
                                                shrink: true,
                                            }} />
                                            {formError &&
                                                formError.errors.number_of_packages && (
                                                    <p className="text-danger small pt-2">
                                                        {formError.errors.number_of_packages}
                                                    </p>
                                                )}
                                        </Col>


                                    </>
                                    <Col md="6" className="my-4 form-group d-flex">
                                        <Table className="table-bordered mt-4 mb-0" >
                                            <thead className="font-weight-bold">
                                                <tr>
                                                    <td> Date  </td>
                                                    <td> No of Packages </td>
                                                    <td>  </td>
                                                </tr>
                                            </thead>
                                            {packagesOutValues && Array.isArray(packagesOutValues) && packagesOutValues.map((element, index) => (
                                                <tr>
                                                    <td> <TextField type="date" value={element.date ?? ''} name="date" onChange={e => handlePackagesFieldChange(index, e)} size="small" className="w-100" id="outlined-basic" label="Date" variant="outlined" InputLabelProps={{
                                                        shrink: true,
                                                    }} />  </td>
                                                    <td> <TextField value={element.no_of_packages_out ?? ''} onBlur={e => onBlurNoOfPackages(index, e)} name="no_of_packages_out" onChange={e => handlePackagesFieldChange(index, e)} size="small" className="w-100" id="outlined-basic" label="No of Packages Out" variant="outlined" />   </td>
                                                    <td>
                                                        <DeleteIcon
                                                            onClick={() => removePackagesField(index)}
                                                            className="text-danger cursor-p">
                                                        </DeleteIcon>
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr className="font-weight-bold">
                                                <td >   </td>
                                                <td >   </td>
                                                <td>
                                                    <Button className="mr-3"
                                                        variant="secondary"
                                                        onClick={() => addPackagesOutField()}>
                                                        Add
                                                    </Button>
                                                </td>
                                            </tr>
                                        </Table>
                                    </Col>
                                </Row>
                                {
                                    commonError && (
                                        <p className="text-danger small pt-2">
                                            {commonError}
                                        </p>
                                    )}



                            </Accordion.Body>
                        </Accordion.Item>

                        <button onClick={submitHandler} className="btn btn-primary ml-3">Save</button>
                    </Accordion>
                </Card>
            </div>
        </>
    );
}

export default WarehouseEdit;
