// import * as React from 'react';
import React, { useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { Button } from "react-bootstrap";
import { Row, Col, } from "reactstrap";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import AxiosContext from '../../store/axios-context';
import AuthContext from '../../store/auth-context';
import { useHistory } from 'react-router';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import ReactExport from "react-export-excel";
import Dropdown from "../Fields/Dropdown";
import InputDate from "../Fields/InputDate";
import moment from 'moment';
import { CSVLink } from "react-csv";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Job Id',
  },
  {
    id: 'code',
    numeric: false,
    disablePadding: true,
    label: 'Job Code',
  },
  {
    id: 'jobType',
    numeric: false,
    disablePadding: false,
    label: 'job Type',
  },
  {
    id: 'project',
    numeric: false,
    disablePadding: false,
    label: 'project',
  },
  {
    id: 'party',
    numeric: false,
    disablePadding: false,
    label: 'party',
  },
  {
    id: 'doe',
    numeric: false,
    disablePadding: false,
    label: 'Date of Execution',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Created Date',
  },
  // {
  //   id: '',
  //   numeric: false,
  //   disablePadding: false,
  //   label: '',
  // },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell className="font-weight-bold"
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>

  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <span></span>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function JobReport() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const history = useHistory();

  const [status, setStatus] = useState(null);
  // const [jobcode, setJobcode] = useState('');
  const [subtype, setSubtype] = useState(null);
  const [project, setProject] = useState(null);
  const [jobtype, setJobtype] = useState(null);
  const [party, setParty] = useState(null);
  const [port, setPort] = useState(null);
  const [formError, setFormError] = useState(null);

  const [jobs, setJob] = useState([]);
  const [projects, setProjects] = useState([]);
  const [jobtypes, setJobtypes] = useState([]);
  const [parties, setParties] = useState([]);
  const [ports, setPorts] = useState([]);
  const [costs, setCosts] = useState([]);
  const [subtypes, setSubtypes] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [doeStartDate, setDoeStartDate] = useState('');
  const [doeEndDate, setDoeEndDate] = useState('');

  const axiosCtx = useContext(AxiosContext);
  const authCtx = useContext(AuthContext);

  const [airwaybillsLocalOrders, setAirwaybillsLocalOrders] = useState([]);
  const [airwaybillLocalOrder, setAirwaybillLocalOrder] = useState(null);

  const startDateChangeHandler = (event) => {
    setStartDate(event.target.value);
  };

  const endDateChangeHandler = (event) => {
    setEndDate(event.target.value);
  };

  const getAirwayBillsLocalOrders = () => {
    axiosCtx.instance.get(`get-all-airwaybill-localorder`)
      .then((response) => {
        setAirwaybillsLocalOrders(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getPorts = () => {
    axiosCtx.instance.get(`port`)
      .then((response) => {
        setPorts(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getStatues = () => {
    axiosCtx.instance.get(`status`)
      .then((response) => {
        setStatuses(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getSubtypes = () => {
    axiosCtx.instance.get(`sub-type`)
      .then((response) => {

        setSubtypes(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getJobs = () => {
    axiosCtx.instance.get(`job`)
      .then((response) => {
        setJob(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getProjects = () => {
    axiosCtx.instance.get(`project`)
      .then((response) => {
        setProjects(response.data.data);
      })
      .catch((error) => {
      });
  };

  const getJobtypes = () => {
    axiosCtx.instance.get(`job-type`)
      .then((response) => {
        setJobtypes(response.data.data);
      })
      .catch((error) => {
      });
  };

  const getParties = () => {
    axiosCtx.instance.get(`party`)
      .then((response) => {
        setParties(response.data.data);
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    if (authCtx.isLoggedIn == false) {
      history.replace('/auth/Login');
    }
    getStatues();
    getSubtypes();
    getPorts();
    getJobs();
    getJobtypes();
    getParties();
    getProjects();
    getAirwayBillsLocalOrders();
  }, []);

  const filterClear = () => {
    setStartDate('');
    setEndDate('');
    setStatus(null);
    setSubtype(null);
    setProject(null);
    setJobtype(null);
    setParty(null);
    setDoeStartDate('');
    setDoeEndDate('');
    setBlankDoe(false);
    setAirwaybillLocalOrder(null);
    getJobs();
  }


  const submitHandler = (event) => {
    event.preventDefault();
    setFormError(null);

    // Call login API
    const formData = new FormData();
    formData.append("job_type_id", jobtype);
    formData.append("status_id", status);
    formData.append("party_id", party);
    formData.append("project_id", project);
    formData.append("sub_type_id", subtype);
    formData.append("port_id", port);

    axiosCtx.instance.post(`job`, formData)
      .then((response) => {
        getJobs();
        // history.replace('job-list');
        history.push({
          pathname: 'job-details',
          state: { detail: response.data, page: 'create' }
        });
      })
      .catch((error) => {
        setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const blankDoeHandleChange = (event) => {
    setBlankDoe(event.target.checked);
  }

  const [blankDoe, setBlankDoe] = useState(false);

  const filterSubmit = (event) => {
    event.preventDefault();
    setFormError(null);

    // Call login API
    const formData = new FormData();
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);
    formData.append("job_type_id", (jobtype) ? jobtype.id : '');
    formData.append("status_id", (status) ? status.id : '');
    formData.append("project_id", (project) ? project.id : '');
    formData.append("party_id", (party) ? party.id : '');
    formData.append("airway_bill_localorder", (airwaybillLocalOrder) ? airwaybillLocalOrder.order_number : '');

    formData.append("date_of_execution_start", doeStartDate);
    formData.append("date_of_execution_end", doeEndDate);

    formData.append("blank_doe", (blankDoe && blankDoe == true) ? 1 : '');

    axiosCtx.instance.post(`job/filter`, formData)
      .then((response) => {
        setJob(response.data.data);
        filterClose();
      })
      .catch((error) => {
        setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = jobs.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    event.stopPropagation();


    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showFilter, setShowFilter] = useState(false);
  const filterClose = () => {
    setShowFilter(false);
    // setStatus('');
    // setJobtype('');
    // setProject('');
    // setParty('');
    // setStartDate('');
    // setEndDate('');
    // getJobs();
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - jobs.length) : 0;
  const [age, setAge] = React.useState('');
  const handleChange = (event) => { setAge(event.target.value); };

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const convertDate = (date) => {
    var newdate = date.split("-").reverse().join("-");
    return moment(newdate).format("YYYY-MM-DD");
  }

  let [excelData, setExcelData] = useState([]);
  const sortJob = () => {
    let data = [...jobs];
    data = data.map(row => (
      {
        ...row,

        job_type: row.job_type ? row.job_type.type : "",
        project: row.project ? row.project.name : "",
        party: row.party ? row.party.name : "",
        created_at: row.created_at ? convertDate(row.created_at) : '',
        status: row.status ? row.status.name : "",
      }
    ));
    setExcelData(data);
  }

  const headers = [
    { label: "Job Id", key: "job_id" },
    { label: "Job Code", key: "job_code" },
    { label: "Job Type", key: "job_type" },
    { label: "Project", key: "project" },
    { label: "Party", key: 'party' },
    { label: "Status", key: 'status' },
    { label: "Created Date", key: 'created_at' },
  ];


  return (
    <div className="content">
      <div className="d-flex justify-content-between align-items-center border-bottom mb-4">
        <h4 className="font-weight-bold m-0">Job Report</h4>

        <div className="form-row align-items-center justify-content-end">
          <div className="col-auto my-1 position-relative">
            <button className="btn btn-simple" onClick={() => setShowFilter((currentShow) => !currentShow)} >
              Filter
            </button>
            <CSVLink data={excelData} headers={headers} className="btn btn-simple" onClick={event => {
              sortJob();
              return true;
            }}>
              Download
            </CSVLink>
            {showFilter ? (
              <div className="filter-container p-4 bg-white shadow-sm rounded">
                <Row>
                  <Col md="6" className="mb-4">
                    <InputDate
                      id="date"
                      placeholder="Start Date"
                      label="Start Date"
                      value={startDate}
                      onChange={startDateChangeHandler}
                    />

                  </Col>
                  <Col md="6" className="mb-4">
                    <InputDate
                      id="date"
                      placeholder="End Date"
                      label="End Date"
                      value={endDate}
                      onChange={endDateChangeHandler}
                    />
                  </Col>
                  <Col md="6" className="mb-4">
                    <Dropdown
                      id="statuses"
                      placeholder="Status"
                      label="name"
                      options={statuses}
                      value={status}
                      onChange={(event, newValue) => {
                        setStatus(newValue);
                      }}
                    />
                  </Col>
                  <Col md="6" className="mb-4">
                    <Dropdown
                      id="jobtypes"
                      placeholder="Job Type"
                      label="type"
                      options={jobtypes}
                      value={jobtype}
                      onChange={(event, newValue) => {
                        setJobtype(newValue);
                      }}
                    />
                  </Col>
                  <Col md="6" className="mb-4">
                    <Dropdown
                      id="subtypes"
                      placeholder="Sub Type"
                      label="sub_type"
                      options={subtypes}
                      value={subtype}
                      onChange={(event, newValue) => {
                        setSubtype(newValue);
                      }}
                    />
                  </Col>
                  <Col md="6" className="mb-4">
                    <Dropdown
                      id="projects"
                      placeholder="Project"
                      label="name"
                      options={projects}
                      value={project}
                      onChange={(event, newValue) => {
                        setProject(newValue);
                      }}
                    />
                  </Col>
                  <Col md="6" className="mb-4">
                    <Dropdown
                      id="parties"
                      placeholder="Parties"
                      label="name"
                      options={parties}
                      value={party}
                      onChange={(event, newValue) => {
                        setParty(newValue);
                      }}
                    />
                  </Col>
                  <Col md="6" className="mb-4">
                    <Dropdown
                      id="airwaybillsLocalOrders"
                      placeholder="AWB/Local order no"
                      label="order_number"
                      options={airwaybillsLocalOrders}
                      value={airwaybillLocalOrder}
                      onChange={(event, newValue) => {
                        setAirwaybillLocalOrder(newValue);
                      }}
                      formError={(formError && formError.errors.airway_bill_localorder) ? formError.errors.airway_bill_localorder : ''}
                    />
                  </Col>
                  <Col md="6" className="mb-4">
                    <InputDate
                      id="date"
                      placeholder="Date of Execution Start"
                      label="Date of Execution Start"
                      value={doeStartDate}
                      onChange={(event) => {
                        setDoeStartDate(event.target.value);
                      }}
                    />

                  </Col>
                  <Col md="6" className="mb-4">
                    <InputDate
                      id="date"
                      placeholder="Date of Execution End"
                      label="Date of Execution End"
                      value={doeEndDate}
                      onChange={(event) => {
                        setDoeEndDate(event.target.value);
                      }}
                    />
                  </Col>
                  <Col md="6" className="mb-4">
                    <FormGroup>
                      <FormControlLabel control={<Checkbox
                        checked={blankDoe}
                        onChange={blankDoeHandleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      } label="Blank Date of Execution" />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="text-right border-top pt-3">
                  <Button
                    className="my-0 mr-3"
                    variant="secondary"
                    onClick={filterClear}
                  >
                    Clear
                  </Button>
                  <Button
                    className="my-0 mr-3"
                    variant="secondary"
                    onClick={filterClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="my-0  "
                    variant="primary"
                    onClick={filterSubmit}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </div>


      </div>

      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={jobs.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(jobs, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell component="th" id={labelId} scope="row" padding="none" > {row.job_id} </TableCell>
                        <TableCell>{row.job_code}</TableCell>
                        <TableCell>{row.job_type.type}</TableCell>
                        <TableCell>{row.project.name}</TableCell>
                        <TableCell>{row.party.name}</TableCell>
                        <TableCell>{row.date_of_execution}</TableCell>
                        <TableCell><span className="badge badge-danger py-1 px-2 ">{row.status.name}</span>  </TableCell>
                        <TableCell>{row.created_at}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={jobs.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

      </Box>

    </div>
  );
}
