import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import logo from "assets/img/print-logo-invoice.png";
import Moment from "moment";

const styles = StyleSheet.create({
  viewer: {
    width: "100%",
    height: "calc(100vh - 94px)",
  },
  page: {
    fontFamily: "Helvetica",
    fontSize: 10,
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 78,
    flexDirection: "column",
    position: "relative",
  },
  header: {
    marginBottom: 20,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  col6: {
    width: "50%",
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  col7: {
    width: "65%",
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  col5: {
    width: "35%",
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },

  bold: {
    fontWeight: "600",
    fontSize: 12,
  },
  dflex: {
    display: "flex",
  },
  tHading: {
    borderWidth: 1,
    borderColor: "#999",
    borderBottomWidth: 1,
    padding: 5,
    flex: "1 1 auto",
    marginBottom: -1,
    marginRight: -1,
    width: 100,
    maxWidth: 100,
  },
  thcol: {
    borderWidth: 1,
    borderColor: "#999",
    borderBottomWidth: 1,
    padding: 5,
    flex: "1 1 auto",
    marginBottom: -1,
    marginRight: -1,
  },
  tdcol: {
    borderWidth: 1,
    borderColor: "#999",
    borderBottomWidth: 1,
    padding: 5,
    flex: "1 1 auto",
    marginBottom: -1,
    marginRight: -1,
  },
  description: {
    borderWidth: 1,
    borderColor: "#999",
    borderBottomWidth: 1,
    padding: 5,
    marginBottom: -1,
    marginRight: -1,
    width: "40%",
  },

  thHeading: {
    borderWidth: 1,
    borderColor: "#999",
    borderBottomWidth: 1,
    padding: 5,
    marginBottom: -1,
    marginRight: -1,
    width: "100%",
    textAlign: "center",
    fontWeight: "bold",
  },

  total: {
    borderWidth: 1,
    borderColor: "#fff",
    borderBottomWidth: 1,
    padding: 5,
    marginTop: 1,
    marginRight: -1,
    width: "50%",
    textAlign: "right",
  },
  tdBorderless: {
    borderWidth: 1,
    borderColor: "#fff",
    borderBottomWidth: 1,
    padding: 5,
    flex: "1 1 auto",
    marginTop: 1,
    marginRight: -1,
  },

  marginY5: {
    marginTop: 25,
    marginBottom: 25,
  },

  border: {
    borderWidth: 1,
    borderColor: "#999",
    paddingRight: 1,
    marginRight: -1,
    paddingBottom: 2,
  },

  withoutBorder: {
    marginLeft: 25,
    textAlign: "center",
    width: 160,
    fontSize: 8,
  },
  padding10: {
    padding: 10,
  },
  w100: {
    width: "100%",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
  },
  footerSub: {
    position: "absolute",
    bottom: 50,
    left: 30,
    right: 30,
  },
  fixedBottom: {
    position: "absolute",
    bottom: 0,
    left: 30,
    right: 30,
  },
  items: {
    borderBottom: "1px solid #ddd",
  },
  itemFooter: {
    borderTop: "1px solid #ddd",
  },
  noItems: {
    fontSize: 16,
    color: "#ff0000",
    textAlign: "center",
    padding: "20px 10px",
  },
});

export default function Quotation(props) {
  const [total, setTotal] = useState({ cost: 0, margin: 0, sum: 0, profit: 0 });

  useEffect(() => {
    if (!props.items || props.items.length <= 0) {
      return;
    }
    let temp = { cost: 0, margin: 0, sum: 0, profit: 0 };
    props.items.forEach((item) => {
      let cost = parseFloat(item.cost);
      if (!isNaN(cost)) {
        temp.cost += cost;
      }
      let sum = parseFloat(item.total);
      if (!isNaN(sum)) {
        temp.sum += sum;
      }
      let profit = parseFloat(item.profit);
      if (!isNaN(profit)) {
        temp.profit += profit;
      }
    });
    if (temp.cost > 0) {
      temp.margin = Number(((temp.profit / temp.cost) * 100).toFixed(3));
    }
    setTotal(temp);
  }, [props.items]);
  Moment.locale("en");
  var converter = require("number-to-words");
  const capitalizeString = (str) => {
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2;
  };
  return (
    <>
      <div className="content pdfViewer">
        <PDFViewer style={styles.viewer}>
          <Document title={`Quotation - ${props.quote_code} `}>
            <Page size="A4" style={styles.page} wrap>
              <View style={styles.header} fixed>
                <Image style={styles.logo} src={logo} />
              </View>

              <View>
                <View style={styles.row} fixed>
                  <Text
                    style={[
                      styles.thcol,
                      styles.bold,
                      { fontSize: 13, textAlign: "center" },
                    ]}
                  >
                    Quotation
                  </Text>
                </View>

                <View style={styles.row} fixed>
                  <View
                    style={[
                      styles.thcol,
                      { minWidth: "20%", lineHeight: "1.5px" },
                    ]}
                  >
                    <Text style={styles.w100}> CUSTOMER NAME & ADDRESS </Text>
                    <Text style={[styles.w100, styles.bold]}>
                      {props.customer.name}
                    </Text>
                    <Text style={[styles.w100, styles.bold]}>
                      {props.customer.address_1}
                    </Text>
                    <Text style={[styles.w100, styles.bold]}>
                      {props.customer.address_2}
                    </Text>
                    <Text style={[styles.w100, styles.bold]}>
                      {props.customer.city}
                    </Text>
                    <Text style={[styles.w100, styles.bold]}>
                      {props.customer.country}
                    </Text>
                    <Text style={[styles.w100, styles.bold]}>
                      {props.customer.vat_number}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tdcol,
                      { padding: 0, borderLeftColor: "#fff" },
                    ]}
                  >
                    <View style={styles.row}>
                      <Text style={styles.tHading}>QUOTE CODE</Text>
                      <Text style={styles.thcol}>{props.quote_code}</Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.tHading}>DATE </Text>
                      <Text style={styles.thcol}>
                        {Moment(props.created_at).format("DD-MM-yyyy")}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.tHading}>FROM </Text>
                      <Text style={styles.thcol}>
                        {props.from.code} - {props.from.name}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.tHading}>TO </Text>
                      <Text style={styles.thcol}>
                        {props.to.code} - {props.to.name}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.tHading}>QUOTE REF</Text>
                      <Text style={styles.thcol}>{props.quote_code}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.border}>
                  <View style={[styles.row, styles.items]}>
                    <Text style={[styles.description, { borderColor: "#fff" }]}>
                      Item Name
                    </Text>
                    <Text
                      style={[
                        styles.tdcol,
                        { textAlign: "right", borderColor: "#fff" },
                      ]}
                    >
                      Cost
                    </Text>
                  </View>
                  {!props.items || props.items.length <= 0 ? (
                    <Text style={styles.noItems}>Sorry, No Items Found !</Text>
                  ) : (
                    props.items.map((item) => (
                      <View style={styles.row}>
                        <Text
                          style={[styles.description, { borderColor: "#fff" }]}
                        >
                          {item.name}
                        </Text>
                        <Text
                          style={[
                            styles.tdcol,
                            { textAlign: "right", borderColor: "#fff" },
                          ]}
                        >
                          {item.total}
                        </Text>
                      </View>
                    ))
                  )}
                  {props.hide_total === "0" && (
                    <View style={[styles.row, styles.itemFooter]}>
                      <Text
                        style={[styles.description, { borderColor: "#fff" }]}
                      >
                        Total
                      </Text>
                      <Text
                        style={[
                          styles.tdcol,
                          { textAlign: "right", borderColor: "#fff" },
                        ]}
                      >
                        {total.sum}
                      </Text>
                    </View>
                  )}
                </View>

                {props.hide_total === "0" && (
                  <View>
                    <View style={[styles.row, styles.bold, { fontSize: "12" }]}>
                      <Text
                        style={[styles.tdcol, { borderRightColor: "#fff" }]}
                      >
                        TOTAL{" "}
                      </Text>
                      <Text
                        style={[
                          styles.tdcol,
                          {
                            textAlign: "right",
                            borderLeftColor: "#fff",
                            paddingRight: 4,
                          },
                        ]}
                      >
                        {total.sum}
                      </Text>
                    </View>
                    <Text style={[{ paddingTop: 5 }]}>
                      TOTAL : {capitalizeString(converter.toWords(total.sum))}{" "}
                      {total.sum - parseInt(total.sum) > 0
                        ? "and Cents " +
                          Number((total.sum - parseInt(total.sum)).toFixed(3)) +
                          "/00 Only"
                        : "Only"}{" "}
                    </Text>
                  </View>
                )}

                <Text
                  style={[
                    {
                      paddingBottom: 5,
                      paddingTop: 5,
                      textAlign: "center",
                      fontSize: 8,
                    },
                  ]}
                >
                  All quotations given in good faith for information purposes
                  only and may be revised without notice. Unless otherwise
                  stated, quotations are valid for 10 days only, assume no
                  intervening currency fluctuations and exclude VAT and Duty.
                  Where both weight and measurement rates are given the higher
                  rate shall prevail. All shipments arranged by us are subject
                  to our company's standard trading conditions - copies
                  available on request. Please note clauses which limit our
                  liability. Errors and omissions excepted.
                </Text>
              </View>
              {/* <View
                style={[
                  styles.footerSub,
                  { borderColor: "#000", borderTopWidth: 1, marginTop: 20 },
                ]}
              > */}
                {/* <View
                  style={[styles.row, { borderWidth: 0, borderColor: "white" }]}
                > */}
                  {/* <View
                    style={[
                      styles.tdcol,
                      { minWidth: "50%", borderWidth: 0, borderColor: "white" },
                    ]}
                  > */}
                    {/* <Text
                      style={[
                        styles.bold,
                        { fontSize: 8, paddingBottom: 5, paddingTop: 5 },
                      ]}
                    >
                      Bank Details (USD):{" "}
                    </Text>
                    <Text style={[{ marginBottom: 3, fontSize: 7 }]}>
                      Sea Trust Shipping Services LLC{" "}
                    </Text>
                    <Text style={[{ marginBottom: 3, fontSize: 7 }]}>
                      RAK BANK: USD A/C # 0242242854002
                    </Text>
                    <Text style={[{ marginBottom: 3, fontSize: 7 }]}>
                      IBAN: AE42 0400 0002 4224 2854 002
                    </Text>
                    <Text style={[{ marginBottom: 3, fontSize: 7 }]}>
                      SWIFT : NRAKAEAK
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tdcol,
                      { borderWidth: 0, borderColor: "white" },
                    ]}
                  >
                    <Text
                      style={[
                        styles.bold,
                        { fontSize: 8, paddingBottom: 5, paddingTop: 5 },
                      ]}
                    >
                      Bank Details (AED):{" "}
                    </Text>
                    <Text style={[{ marginBottom: 3, fontSize: 7 }]}>
                      Sea Trust Shipping Services LLC{" "}
                    </Text>
                    <Text style={[{ marginBottom: 3, fontSize: 7 }]}>
                      RAK BANK: AED A/C # 0242242854001
                    </Text>
                    <Text style={[{ marginBottom: 3, fontSize: 7 }]}>
                      IBAN: AE69 0400 0002 4224 2854 001
                    </Text>
                    <Text style={[{ marginBottom: 3, fontSize: 7 }]}>
                      SWIFT : NRAKAEAK
                    </Text>
                  </View> */}
                {/* </View> */}
              {/* </View> */}
              <View
                style={[styles.footer, styles.fixedBottom, { marginTop: 15 }]}
                fixed
              >
                <Text
                  style={[
                    {
                      fontSize: "8.5",
                      paddingBottom: 10,
                      paddingTop: 10,
                      textAlign: "center",
                    },
                  ]}
                >
                  Warehouse # 366 , Dubai Maritime City. Dubai , UAE . PO BOX
                  182693 Tel : +971-4-8786950 E-mail : info@seatrustshipping.com
                  Web: www.seatrustshipping.com
                </Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </div>
    </>
  );
}
