import axios from 'axios';

const instance = axios.create({
   //baseURL: "https://shipping.cordiacesolutions.com/shipping-api/public/api",
  // baseURL: "http://127.0.0.1:8000/api",
  baseURL: "https://sts.cordiacesolutions.com/backend/public/api",
  headers: { Accept: 'application/json' }, 
});

export default instance;
