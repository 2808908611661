import Quotation from "components/Pdf/Quotation";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import AuthContext from "store/auth-context";
import AxiosContext from "store/axios-context";

export default function GeneratePdf() {
  const params = useParams();
  const history = useHistory();
  const axiosCtx = useContext(AxiosContext);
  const authCtx = useContext(AuthContext);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (authCtx.isLoggedIn == false) {
      history.replace("/auth/Login");
    }
    if (params.id) {
      axiosCtx.instance
        .get(`show-quotation?id=${params.id}`)
        .then((response) => {
          console.log(response.data.data);
          setData(response.data.data);
        })
        .catch((error) => {
          alert("Something went wrong!");
        });
    }
  }, []);

  return <Fragment>{data && <Quotation {...data} />}</Fragment>;
}
